<template>
  <v-container class="container-comprovante text-center text-subtitle-2">
    <v-row id="actions">
      <v-col class="d-flex pa-8">
        <v-btn type="button" color="primary" @click="fecharFaturaDetalhada"
          >Fechar</v-btn
        >
        <v-spacer />
        <v-btn color="secondary" @click="imprimirFaturaDetalhada"
          >Imprimir</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="justify-space-between" id="logos">
      <v-col cols="4">
        <v-img
          class="mx-auto"
          contain
          height="100"
          width="150"
          src="@/assets/images/icon/logo.png"
        />
      </v-col>
      <v-col cols="4">
        <v-img
          class="mx-auto"
          contain
          height="100"
          width="150"
          src="@/assets/images/icon/logo.png"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1>Faturamento</h1>
        <p></p>
        <h3>Fatura número: {{ detalheFatura.numero }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="detalheFatura.itens"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:[`item.valor`]="{ item }">
            {{ toReal(item.valor) || toReal(0) }}
          </template>
          <template v-slot:[`body.append`]>
            <tr class="font-weight-bold">
              <td :colspan="headers.length - 1" align="left">Total</td>
              <td align="left">{{ toReal(total) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { toReal } from '@/mixins/convertion.js'

export default {
  name: 'DetalheFatura',
  mixins: [toReal],
  inject: ['fecharFaturaDetalhada'],
  props: {
    detalheFatura: Object,
  },
  data() {
    return {
      headers: [
        {
          text: 'Placa',
          divider: true,
          sortable: false,
          value: 'servico_triado.placa',
        },
        {
          text: 'Serviço',
          divider: true,
          sortable: false,
          value: 'servico_triado.servico',
        },
        {
          text: 'Valor',
          divider: true,
          sortable: false,
          value: 'valor',
        },
      ],
      valorTotal: 0,
    }
  },
  methods: {
    imprimirFaturaDetalhada() {
      setTimeout(window.print, 500)
    },

  },
  computed: {
    total() {
      return this.detalheFatura.itens.reduce((acc, f) => acc + Number(f.valor), 0)
    },
  },
}
</script>

<style>
.container-comprovante p {
  text-transform: uppercase;
  line-height: 5px;
}

strong::before {
  content: ' ';
}

.label::after {
  content: ':';
}

@media print {
  .v-application--wrap,
  .v-dialog button {
    display: none;
  }

  .v-dialog {
    display: block;
    height: 100vh;
    width: 100vw;
    margin: 0;
  }

  .v-card {
    height: 100%;
    width: 100%;
  }

  #actions {
    display: none;
  }

  #logos {
    margin-top: -20px;
  }

  @page {
    margin: 0;
  }
}
</style>
