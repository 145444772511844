<template>
  <v-container fluid>
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs" />
    <v-row>
      <v-col cols="12" sm="12">
        <faturamento-list-filtro @search="handleGetFaturamento" :isTransportadora="this.isTransportadora" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-tabs v-model="tab" align-with-title>
          <v-tab href="#relatorioTransportadora" @change="carregarConteudo('relatorioTransportadora')">Relatório por
            Transportadora</v-tab>
          <v-tab href="#abertos" @change="carregarConteudo('abertos')">Em Aberto</v-tab>
          <v-tab href="#faturados" @change="carregarConteudo('faturados')"
            @click="carregarNovosFaturamentos()">Faturados</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item class="mx-5 pa-5" eager value="relatorioTransportadora">
            <transportadora-relatorio-faturamento ref="relatorioTransportadora" :isTransportadora="this.isTransportadora"
              :transportadora="this.empresaAtual.public_id"></transportadora-relatorio-faturamento>
          </v-tab-item>
          <v-tab-item class="mx-5 pa-5" eager value="abertos">
            <aberto-relatorio-faturamento ref="abertos" :isTransportadora="this.isTransportadora"
              :transportadora="this.empresaAtual.public_id"></aberto-relatorio-faturamento>
          </v-tab-item>
          <v-tab-item class="mx-5 pa-5" eager value="faturados">
            <fatura-relatorio-faturamento ref="faturados" :key="componentKey"></fatura-relatorio-faturamento>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { toReal, parseDatetime } from '@/mixins/convertion.js'
import faturamentoListFiltro from '@/components/financeiro/faturamento/relatorio/FaturamentoListFiltro.vue'
import TransportadoraRelatorioFaturamento from '@/components/financeiro/faturamento/relatorio/Transportadora.vue'
import AbertoRelatorioFaturamento from '@/components/financeiro/faturamento/relatorio/Aberto.vue'
import FaturaRelatorioFaturamento from '@/components/financeiro/faturamento/relatorio/Fatura.vue'
import Perfil from '@/utils/perfil'

export default {
  name: 'Relatorio',

  components: {
    TransportadoraRelatorioFaturamento,
    AbertoRelatorioFaturamento,
    FaturaRelatorioFaturamento,
    faturamentoListFiltro,
  },
  mixins: [toReal, parseDatetime],
  data: function () {
    return {
      tab: 'abertos',
      page: {
        title: 'Relatório de Faturamento',
      },
      breadcrumbs: [
        {
          text: 'Financeiro',
          disabled: true,
        },
        {
          text: 'Faturamento',
          disabled: true,
        },
        {
          text: 'Relatório',
          disabled: false,
        },
      ],
      componentKey: 0,
      isTransportadora: false,
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },
  async created() {
    this.isTransportadora = this.empresaAtual.perfisColaborador.includes(Perfil.Transportadora) && this.empresaAtual.perfisColaborador.length == 1;
  },
  methods: {
    ...mapActions('faturamento', ['getFaturamento']),

    carregarNovosFaturamentos() {
      this.componentKey += 1
    },

    async carregarConteudo(tab) {
      this.tab = tab
      this.isTransportadora = this.empresaAtual.perfisColaborador.includes(Perfil.Transportadora) && this.empresaAtual.perfisColaborador.length == 1;
      console.log(this.isTransportadora)
      if (tab == 'relatorioTransportadora') {
        await this.$refs[tab].getTotalPorTransportadora();
      }
      if (tab == 'abertos') {
        await this.$refs[tab].getFaturamentoAbertos();
        await this.$refs[tab].handleTotalEmAberto();
      }
      if (this.tab == 'faturados') {
        await this.$refs[tab].getFaturamento()
      }
    },

    async handleGetFaturamento() {
      await this.carregarConteudo(this.tab)
    },
  },
}
</script>
