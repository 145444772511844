<template>
  <div>
    <v-overlay v-model="carregandoDados">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-data-table :headers="headers" :items="faturamento" sort-by="id" class="border">
      <template v-slot:[`item.acoes`]="{ item }">
        <v-checkbox :value="item" multiple v-model="selecionados" />
      </template>
      <template v-slot:[`item.data`]="{ item }">
        {{ new Date(item.data).toLocaleString('pt-BR') }}
      </template>
      <template v-slot:[`item.transportadora`]="{ item }">
        {{ item.transportadora.business_name }}
      </template>
      <template v-slot:[`item.valor`]="{ item }">
        {{ toReal(item.valor) || toReal(0) }}
      </template>
      <template v-slot:[`item.forma_pagamento`]="{ item }">
        {{ formaPagamentoToString(item.forma_pagamento) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        {{ item.status }}
      </template>
      <template v-slot:[`item.faturado_em`]="{ item }">
        {{ new Date(item.faturado_em).toLocaleDateString('pt-BR') }}
      </template>
      <template #no-data v-if="!canList">
        <span class="red--text">Você não tem permissão para visualizar as informações desta
          tela</span>
      </template>
    </v-data-table>
    <v-btn color="info" class="w-100 my-1" @click="getDownload">
      <v-icon class="mx-2" left dark> mdi-download </v-icon>
      Baixar Planilha</v-btn>
    <v-divider class="ma-5"></v-divider>
    <div class="text-h6 pa-4">Totais:</div>
    <v-data-table hide-default-footer :headers="headersTotalEmAberto" :items="totalEmAberto" sort-by="id" class="border">
      <template v-slot:[`item.valor_total`]="{ item }">
        {{ toReal(item.valor_total) || toReal(0) }}
      </template>
      <template #no-data v-if="!canList">
        <span class="red--text">Você não tem permissão para visualizar as informações desta
          tela</span>
      </template>
    </v-data-table>

    <v-btn v-if="canCreate" @click="setGerarFatura()" color="info" class="w-100 mt-5">Gerar Fatura</v-btn>


  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { toReal } from '@/mixins/convertion.js'

import { BasicPermissions, Sequences } from '@/utils/permissions'

import FaturamentoAPI from '@/services/faturamento/index'
import axios from 'axios'
import { formaPagamentoToString, statusToString } from '@/utils/faturamento'

export default {
  name: 'AbertoRelatorioFaturamento',
  emits: ['atualizar-fatura'],
  mixins: [toReal],
  props: {
    isTransportadora: {
      type: Boolean,
      required: true,
    },
    transportadora: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      faturamento: [],
      selecionados: [],
      servicosFaturados: [],
      itemFaturado: {},
      novoFaturamento: {
        proprietario: '',
        faturado_por: '',
        transportadora: '',
        status: 0,
      },
      faturamentoPublicId: '',
      carregandoDados: false,
      dialog: null,
      headers: [
        {
          text: 'Ações',
          divider: true,
          sortable: false,
          value: 'acoes',
          class: 'primary white--text',
        },
        { text: 'Senha', value: 'senha' },
        { text: 'Serviço', value: 'servico' },
        { text: 'Valor', value: 'valor' },
        { text: 'Forma de pagamento', value: 'forma_pagamento' },
        { text: 'Data', value: 'data' },
        { text: 'Placa', value: 'placa_cavalo' },
        { text: 'Motorista', value: 'motorista' },
        { text: 'Transportador', value: 'transportadora' },
        { text: 'Cliente', value: 'cliente' },
        { text: 'Terminal', value: 'terminal' },
        { text: 'Status', value: 'status' },
      ],
      headersTotalEmAberto: [
        { text: 'Serviço', value: 'servico' },
        { text: 'Quantidade Total', value: 'quantidade_total' },
        { text: 'Valor Total', value: 'valor_total' },
      ],
      fields_excel: {
        Data: {
          callback: (value) => {
            let dataServico = new Date(value.data).toLocaleString("pt-br")
            return `${dataServico}`
          },
        },
        Placa: 'placa_cavalo',
        Motorista: 'motorista',
        Valor: 'valor',
        Cliente: 'cliente',
      },
      fields_excel_em_aberto: {
        Serviço: 'servico',
        'Quantidade Total': 'quantidade_total',
        'Valor Total': 'valor_total',
      },
      totalEmAberto: [],
    }
  },

  methods: {
    ...mapActions('faturamento', [
      'getFaturamentoByParams',
      'getTotalEmAberto',
      'addNovoFaturamento',
    ]),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    formaPagamentoToString,
    statusToString,

    async getFaturamentoAbertos() {
      try {
        this.carregarDados()

        if (this.isTransportadora) {
          let empresa = null
          if (this.params.gate) {
            empresa = { public_id: this.params.gate }
          }
          const results = await this.getFaturamentoByParams({
            ...this.params,
            transportadora: this.transportadora,
            empresa
          })
          this.faturamento = results.servicos
        } else {
          const results = await this.getFaturamentoByParams({
            ...this.params,
          })
          this.faturamento = results.servicos
        }
        this.finalizarCarregamentoDados()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    async getDownload() {
      let data = []
      try {
        const uri = axios.getUri({
          url: 'api/v1/tenants/gates/' + this.empresaAtual.public_id + '/download/',
          params: {
            ...this.params,
          }
        })
        const base = axios.defaults.baseURL
        // adding slash if necessary
        const slash = base.slice(-1) == '/' ? '' : '/'
        const url = base + slash + uri
        window.open(url)
      } finally {
        this.$emit('loaded')
      }
      return data
    },
    async faturarServico() { },


    async setGerarFatura() {
      const faturamento = this.selecionados[0]
      this.novoFaturamento.proprietario = this.empresaAtual.public_id
      this.novoFaturamento.faturado_por = faturamento.colaborador.public_id
      this.novoFaturamento.transportadora = faturamento.transportadora.public_id
      try {
        const { public_id } = await this.addNovoFaturamento(
          this.novoFaturamento
        )
        this.faturamentoPublicId = public_id
        this.selecionados.forEach(async (servico) => {
          try {
            this.itemFaturado = {
              servico_triado: servico.public_id,
              valor: servico.valor,
              faturamento: this.faturamentoPublicId,
            }
            await FaturamentoAPI.createItemDoFaturamento(
              this.empresaAtual.public_id,
              this.itemFaturado
            )
          } catch (e) {
            this.errorMessage(e.response.data)
          }
        })
        this.successMessage('Faturamento gerado com sucesso')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
      this.$emit('atualizar-fatura')
      this.getFaturamentoAbertos()


      this.faturamentoPublicId = ''
    },

    async handleTotalEmAberto() {
      try {
        this.carregarDados()

        if (this.isTransportadora) {
          let empresa = null
          if (this.params.gate) {
            empresa = { public_id: this.params.gate }
          }
          this.totalEmAberto = await this.getTotalEmAberto({
            ...this.params,
            transportadora: this.transportadora,
            empresa
          })
        } else {
          this.totalEmAberto = await this.getTotalEmAberto({
            ...this.params,
          })
        }

        this.finalizarCarregamentoDados()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    servicosSelecionadosFaturamento() { },

    carregarDados() {
      this.carregandoDados = true
    },
    finalizarCarregamentoDados() {
      this.carregandoDados = false
    },

    async getAllInfo() {
      await this.getFaturamentoAbertos()
      await this.handleTotalEmAberto()
    },
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),

    permission() {
      return Sequences.Faturamento.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
    ...mapState('relatorioFaturamento', ['params']),
  },

  async created() { },
}
</script>
