export function data() {
  return {
    settingFilters: false,
    dependents: {
      fornecedor: ['produto', 'sub_produto', 'cliente', 'transportadora'],
      terminal: ['armazem'],
    },
    gates: [],
    params: {
      data_inicio: {
        value: '',
        visible: false,
      },
      data_fim: {
        value: '',
        visible: false,
      },
      data_inicio_triagem: {
        value: '',
        visible: false,
      },
      data_fim_triagem: {
        value: '',
        visible: false,
      },
      gate: {
        value: '',
        visible: false,
        // get: () => { },
      },
      proprietario: {
        value: '',
        visible: false,
        // get: () => { },
      },
      fornecedor: {
        value: '',
        visible: false,
        get: this.getFornecedores,
      },
      produto: {
        value: '',
        visible: false,
      },
      sub_produto: {
        value: '',
        visible: false,
      },
      cliente: {
        value: '',
        visible: false,
        get: this.getClientes,
      },
      transportadora: {
        value: '',
        visible: false,
        get: this.getTransportadoras,
      },
      terminal: {
        value: '',
        visible: false,
        get: this.getContratos,
      },
      armazem: {
        value: '',
        visible: false,
      },
      regiao: {
        value: '',
        visible: false,
        get: this.getRegioes,
      },
      representante: {
        value: '',
        visible: false,
        get: this.getRepresentantes,
      },
      motorista: {
        value: '',
        visible: false,
        get: this.getMotoristas,
      },
      placa_cavalo: {
        value: '',
        visible: false,
      },
      eixo: {
        value: '',
        visible: false,
      },
      servico: {
        value: '',
        visible: false,
      },
    },
    allOptions: [
      'data_inicio',
      'data_fim',
      'data_inicio_triagem',
      'data_fim_triagem',
      'fornecedor',
      'servico',
      'produto',
      'sub_produto',
      'terminal',
      'armazem',
      'cliente',
      'transportadora',
      'regiao',
      'representante',
      'motorista',
      'placa_cavalo',
      'eixo',
    ],
  }
}
