<template>
  <v-col>
    <v-autocomplete
      v-model="servicosSelecionados"
      :items="servicos"
      :label="label"
      :prepend-icon="icon"
      multiple
      no-filter
      item-text="descricao"
      item-value="id"
      @input="$emit('input', $event)"
    >
    </v-autocomplete>
  </v-col>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'FaturamentoListFiltroModalItemInputServico',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    mask: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
    value: {
      required: true,
    },
  },
  data: () => ({
    servicosSelecionados: [],
    params: {
      inicias: {
        value: '',
      },
    },
  }),
  methods: {
    ...mapActions('patio', [
      'getServicosAtivos',
    ]),
    ...mapActions('template', ['successMessage']),
  },
  computed: {
    ...mapState('patio', ['servicos', 'servicosNaoAtivos']),
    ...mapState('auth', ['empresaAtual', 'user']),
  },
  async created() 
  {
    await this.getServicosAtivos({
      proprietario: this.empresaAtual.public_id,
      ativo: true,
    })
  },
}
</script>