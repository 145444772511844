<template>
  <div>
    <v-data-table :headers="headers" :items="totalPorTransportadora" sort-by="id" class="border">
      <template v-slot:[`item.valor_total`]="{ item }">
        {{ toReal(item.valor_total) || toReal(0) }}
      </template>
      <template v-slot:[`item.cnpj`]="{ item }">
        {{ item.cnpj | VMask('##.###.###/####-##') }}
      </template>
      <template v-slot:[`body.append`]>
        <tr class="font-weight-bold">
          <td>Total</td>
          <td></td>
          <td>{{ toReal(total) }}</td>
        </tr>
      </template>
      <template #no-data v-if="!canList">
        <span class="red--text">Você não tem permissão para visualizar as informações desta
          tela</span>
      </template>
    </v-data-table>
    <download-excel v-if="canCreate" name="transportadoras.xls" :data="totalPorTransportadora" :fields="fields_excel">
      <v-btn color="info" class="w-100 mt-5"> Gerar Excel </v-btn>
    </download-excel>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { toReal } from '@/mixins/convertion.js'

import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'TransportadoraRelatorioFaturamento',

  mixins: [toReal],
  props: {
    isTransportadora: {
      type: Boolean,
      required: true,
    },
    transportadora: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Transportadora', value: 'transportadora' },
        { text: 'Cnpj', value: 'cnpj' },
        { text: 'Valor', value: 'valor_total' },
      ],
      fields_excel: {
        'Transportadora': 'transportadora',
        'Cnpj': 'cnpj',
        'Valor': 'valor_total',
      },
      totalPorTransportadora: [],
    }
  },

  computed: {
    total() {
      return this.totalPorTransportadora.reduce(
        (acc, total) => acc + Number(total.valor_total),
        0
      )
    },

    permission() {
      return Sequences.Faturamento.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
    ...mapState('relatorioFaturamento', ['params']),

  },

  methods: {
    ...mapActions('faturamento', ['getTotalTransportadora']),

    async getTotalPorTransportadora() {
      if (this.isTransportadora) {
        let empresa = null
        if (this.params.gate) {
          empresa = { public_id: this.params.gate }
        }

        let gate = { public_id: "" }
        gate.public_id = this.params.gate

        this.totalPorTransportadora = await this.getTotalTransportadora({
          ...this.params,
          transportadora: this.transportadora, empresa
        }, gate)

      } else {
        this.totalPorTransportadora = await this.getTotalTransportadora({
          ...this.params,
        })
      }
    },
  },

  async created() {
    if (this.canList) {
      await this.getTotalPorTransportadora()
    }
  },
}
</script>
