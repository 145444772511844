<template>
  <div>
    <v-row>
      <v-col col="12">
        <v-data-table
          :headers="headers"
          :items="faturamentos"
          :items-per-page="10"
          :loading="loadTable"
          loading-text="Carregando Faturamentos... Por favor aguarde"
          class="border"
        >
          <template v-slot:[`item.valor`]="{ item }">
            {{ toReal(valorTotal(item)) || toReal(0) }}
          </template>
          <template v-slot:[`item.faturado_em`]="{ item }">
            {{ new Date(item.faturado_em).toLocaleDateString('pt-BR') }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="canUpdate"
              small
              class="mr-1 mb-3"
              title="Detalhes da Fatura"
              @click="detalhesDaFatura(item)"
              >mdi-magnify</v-icon
            >
            <v-icon
              v-if="canUpdate"
              title="Faturar Serviços"
              small
              class="mr-1 mb-3"
              @click="alterarFaturaStatusFaturada(item)"
              >mdi-file-document-check</v-icon
            >
            <v-icon
              v-if="canUpdate"
              title="Pagar Fatura"
              small
              class="mr-1 mb-3"
              @click="alterarFaturaStatusPaga(item)"
              >mdi-cash-multiple</v-icon
            >
            <v-icon
              v-if="canUpdate"
              small
              class="mb-3"
              title="Cancelar Fatura"
              @click="alterarFaturaStatusCancelada(item)"
              >mdi-close-circle-outline</v-icon
            >
            <confirm-dialog
              v-if="dialogPagamento"
              @confirm="confirmarPagamento"
            />
            <confirm-dialog
              v-if="dialogFaturamento"
              @confirm="confirmarFaturamento"
            />
          </template>
          <template v-slot:[`body.append`]>
            <tr class="font-weight-bold">
              <td :colspan="headers.length - 1">Total</td>
              <td>{{ toReal(totalFaturamento) }}</td>
            </tr>
          </template>

          <template #no-data v-if="!canList">
            <span class="red--text"
              >Você não tem permissão para visualizar as informações desta
              tela</span
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <download-excel
      v-if="canCreate"
      name="faturas.xls"
      :data="faturamentos"
      :fields="fields_excel"
    >
      <v-btn color="info" class="w-100 mt-5"> Gerar Excel </v-btn>
    </download-excel>

    <v-dialog v-model="showDetalheFatura" max-width="1000px">
      <v-card>
        <detalhe-fatura :detalheFatura="faturaCompleta" />
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCancelamento" max-width="500px">
      <v-card>
        <v-card-title class="v-card">
          <span class="headline">Informe os motivos para cancelar:</span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="faturaAtual.observacao"
            full-width
            single-line
            label="Motivos..."
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black darken-1" text @click="dialogCancelamento = false"
            >Cancelar</v-btn
          >
          <v-btn type="button" color="error" @click="cancelarFaturamento()"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { toReal } from '@/mixins/convertion.js'
import { BasicPermissions, Sequences } from '@/utils/permissions'

import DetalheFatura from '@/components/financeiro/faturamento/relatorio/DetalheFatura.vue'
import ConfirmDialog from '@/components/template/ConfirmDialog'

export default {
  name: 'FaturaRelatorioFaturamento',
  mixins: [toReal],
  components: {
    DetalheFatura,
    ConfirmDialog,
  },
  provide() {
    return {
      fecharFaturaDetalhada: this.fecharFaturaDetalhada,
    }
  },

  data() {
    return {
      showDetalheFatura: false,
      faturamentos: [],
      faturaCompleta: {},
      faturaEditada: {},
      faturaAtual: {},
      dialogPagamento: false,
      dialogFaturamento: false,
      dialogCancelamento: false,

      headers: [
        { text: 'Data', value: 'faturado_em' },
        { text: 'Número', value: 'numero' },
        {
          text: 'Transportadora',
          value: 'transportadora',
        },
        { text: 'Faturado por', value: 'faturado_por' },
        { text: 'Valor', value: 'valor' },

        { text: 'Status', value: 'status' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],

      fields_excel: {
        Data: {
          callback: (value) => {
            let dataFaturamento = new Date(value.faturado_em).toLocaleString("pt-br")
            return `${dataFaturamento}`
          },
        },
        Transportadora: 'transportadora',
        Usuário: 'faturado_por',
        Valor: {
          callback: (value) => {
            switch (value.itens.length) {
              case 1:
                return `R$ ${Number(value.itens[0].valor)},00`
              case 2:
                return `R$ ${
                  Number(value.itens[0].valor) + Number(value.itens[1].valor)
                },00`
              case 3:
                return `R$ ${
                  Number(value.itens[0].valor) +
                  Number(value.itens[1].valor) +
                  Number(value.itens[2].valor)
                },00`
                case 4:
                return `R$ ${
                  Number(value.itens[0].valor) +
                  Number(value.itens[1].valor) +
                  Number(value.itens[2].valor) +
                  Number(value.itens[3].valor)
                },00`
              default:
                return `${'-'}`
            }
          },
        },
      },
      loadTable: true,
    }
  },

  methods: {
    ...mapActions('faturamento', [
      'getFaturamento',
      'editFaturamentoStatusPago',
      'editFaturamentoStatusFaturado',
      'editFaturamentoStatusCancelado',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    fecharFaturaDetalhada() {
      this.showDetalheFatura = false
      this.$router.push({
        name: 'faturamento',
      })
    },

    valorTotal(fatura) {
      return fatura.itens.reduce(
        (acc, f) => (acc.valor ? acc.valor : acc) + Number(f.valor),
        0
      )
    },

    somaFaturas() {
      let totalFaturas = 0
      this.faturamentos.forEach((fatura) => {
        let totalUnicaFatura = fatura.itens.reduce(
          (acc, f) => (acc.valor ? acc.valor : acc) + Number(f.valor),
          0
        )
        totalFaturas = totalFaturas + totalUnicaFatura
      })
      return totalFaturas
    },

    detalhesDaFatura(fatura) {
      this.faturaCompleta = fatura
      this.showDetalheFatura = true
    },

    async recarregarListagemFaturas() {
      this.faturamentos = await this.getFaturamento()
    },

    alterarFaturaStatusPaga(item) {
      this.dialogPagamento = true
      this.dialogFaturamento = false
      this.faturaAtual = item
      this.setDialogMessage({
        title: 'Confirmar Pagamento',
        message: 'Deseja realizar o pagamento deste faturamento?',
      })
    },

    async confirmarPagamento() {
      try {
        await this.editFaturamentoStatusPago(this.faturaAtual)
        this.successMessage('Faturamento pago com sucesso')
        this.dialogPagamento = false
        this.recarregarListagemFaturas()
      } catch (error) {
        this.errorMessage('Erro ao realizar o pagamento: ' + error)
      }
    },

    alterarFaturaStatusFaturada(item) {
      this.dialogPagamento = false
      this.dialogFaturamento = true
      this.faturaAtual = item
      this.setDialogMessage({
        title: 'Confirmar Faturamento',
        message: 'Deseja realizar o faturamento dos serviços?',
      })
    },

    async confirmarFaturamento() {
      try {
        await this.editFaturamentoStatusFaturado(this.faturaAtual)
        this.successMessage('Faturamento pago com sucesso')
        this.dialogFaturamento = false
        this.recarregarListagemFaturas()
      } catch (error) {
        this.errorMessage('Erro ao realizar o faturamento: ' + error)
      }
    },

    alterarFaturaStatusCancelada(item) {
      this.dialogCancelamento = true
      this.faturaAtual = item
    },

    async cancelarFaturamento() {
      try {
        await this.editFaturamentoStatusCancelado(this.faturaAtual)
        this.successMessage('Faturamento cancelado com sucesso')
        this.recarregarListagemFaturas()
        this.loadTable = false
      } catch (error) {
        this.errorMessage('Erro ao cancelar faturamento: ' + error)
      }
      this.dialogCancelamento = false
    },
  },

  computed: {
    ...mapState('faturamento', ['faturamento']),
    ...mapState('auth', ['empresaAtual']),

    totalFaturamento() {
      return this.somaFaturas()
    },
    permission() {
      return Sequences.Faturamento.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },

  async created() {
    if (this.canList) {
      this.loadTable = true
      this.faturamentos = await this.getFaturamento()
      this.loadTable = false
    }
  },
}
</script>
