var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{model:{value:(_vm.carregandoDados),callback:function ($$v) {_vm.carregandoDados=$$v},expression:"carregandoDados"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.faturamento,"sort-by":"id"},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item,"multiple":""},model:{value:(_vm.selecionados),callback:function ($$v) {_vm.selecionados=$$v},expression:"selecionados"}})]}},{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.data).toLocaleString('pt-BR'))+" ")]}},{key:"item.transportadora",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.transportadora.business_name)+" ")]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toReal(item.valor) || _vm.toReal(0))+" ")]}},{key:"item.forma_pagamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formaPagamentoToString(item.forma_pagamento))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.faturado_em",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.faturado_em).toLocaleDateString('pt-BR'))+" ")]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)}),_c('v-btn',{staticClass:"w-100 my-1",attrs:{"color":"info"},on:{"click":_vm.getDownload}},[_c('v-icon',{staticClass:"mx-2",attrs:{"left":"","dark":""}},[_vm._v(" mdi-download ")]),_vm._v(" Baixar Planilha")],1),_c('v-divider',{staticClass:"ma-5"}),_c('div',{staticClass:"text-h6 pa-4"},[_vm._v("Totais:")]),_c('v-data-table',{staticClass:"border",attrs:{"hide-default-footer":"","headers":_vm.headersTotalEmAberto,"items":_vm.totalEmAberto,"sort-by":"id"},scopedSlots:_vm._u([{key:"item.valor_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toReal(item.valor_total) || _vm.toReal(0))+" ")]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)}),(_vm.canCreate)?_c('v-btn',{staticClass:"w-100 mt-5",attrs:{"color":"info"},on:{"click":function($event){return _vm.setGerarFatura()}}},[_vm._v("Gerar Fatura")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }